import React from 'react';
import Icon from 'icon';

import s from './form-checkbox.module.scss';

export default function FormCheckbox({
  className,
  children,
  checked,
  id,
  ...props
}) {
  return (
    <div className={`${s.root} ${className || ''}`}>
      <label id={props.name} htmlFor={id}>
        {children}
      </label>
      <span className={`${s.check} ${checked ? s.checked : ''} input-check`}>
        <Icon icon="check" />
      </span>
      <input type="checkbox" id={id} checked={checked} {...props} />
    </div>
  );
}
