import React, { useState } from 'react';
import { navigate } from '@reach/router';
import Container from 'container';
import Headline from 'headline';
import Input from 'shared/form-input';
import Button from 'button';
import Checkbox from 'shared/form-checkbox';
import axios from 'axios';
import { useFormInput, useErrors, useSubmitReady } from 'hooks/useForm';

import s from './form.module.scss';

const initialCheckboxes = [
  'eCommerce',
  'Website development',
  'Lead generation',
  'SEO',
  'Ongoing support',
  'Copywriting',
  'Branding / Creative',
  'Other'
]
  .map(name => {
    const value = name
      .toLowerCase()
      .replace('/', '')
      .split(' ')
      .join('');
    return {
      name,
      value,
      checked: false
    };
  })
  .reduce((acc, curr) => ({ ...acc, [curr.value]: curr }), {});

export default function Form() {
  // STATE
  const [checkboxes, setCheckboxes] = useState(initialCheckboxes);
  const [emailList, setEmailList] = useState(true);
  const checkboxMap = Object.keys(checkboxes).map(val => checkboxes[val]);

  // CUSTOM HOOKS
  const email = useFormInput('');
  const name = useFormInput('');
  const phone = useFormInput('');
  const company = useFormInput('');
  const website = useFormInput('');
  const info = useFormInput('');
  const emailErrors = useErrors({ email: email.state });
  const nameErrors = useErrors({ name: name.state });
  const phoneErrors = useErrors({ phone: phone.state });
  const submitReady = useSubmitReady({
    email: email.state,
    name: name.state
  });

  // METHODS
  const handleCheck = e => {
    const { name } = e.target;
    setCheckboxes(currentCheckboxes => ({
      ...currentCheckboxes,
      [name]: {
        ...currentCheckboxes[name],
        checked: !currentCheckboxes[name].checked
      }
    }));
  };

  const handleEmailList = () => {
    setEmailList(previousEmailList => !previousEmailList);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const inputs = [email, phone, name, website, company, info];
      const payload = {
        email: email.state.value,
        phone: phone.state.value,
        name: name.state.value,
        website: website.state.value,
        company: company.state.value,
        info: info.state.value,
        categories: checkboxMap.filter(box => box.checked).map(box => box.name),
        emailList
      };
      inputs.forEach(input => {
        input.setters.setValue('');
        input.setters.setTouched(false);
        input.setters.setBlurred(false);
      });
      await setCheckboxes(initialCheckboxes);
      await axios(
        'https://cxn-agency.firebaseio.com/contact-page-form.json',
        {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          data: payload
        }
      )
        .then(x => {
          navigate('/thanks/');
        })
        .catch(err => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container className={s.root}>
      <Headline h1 el="h4" className={s.title}>
        <span>FIRST, </span>
        TELL US ABOUT
        <br />
        YOUR BUSINESS…
      </Headline>
      <form className={s.form} onSubmit={handleSubmit}>
        <div className={s.formGroup}>
          <Input
            type="text"
            required
            id="name"
            name="name"
            className={s.input}
            value={name.state.value}
            error={nameErrors.name}
            {...name.events}
          >
            Full name:
          </Input>
          <Input
            type="tel"
            id="phone"
            name="phone"
            className={s.input}
            value={phone.state.value}
            error={phoneErrors.phone}
            {...phone.events}
          >
            Phone number:
          </Input>
          <Input
            type="text"
            required
            id="email"
            name="email"
            className={s.input}
            value={email.state.value}
            error={emailErrors.email}
            {...email.events}
          >
            Email address:
          </Input>
          <Input
            type="text"
            id="company"
            name="company"
            className={s.input}
            value={company.state.value}
            {...company.events}
          >
            Company name:
          </Input>
          <Input
            type="text"
            id="website"
            name="website"
            placeholder="http://"
            className={s.input}
            value={website.state.value}
            {...website.events}
          >
            Current URL (if you have one):
          </Input>
        </div>
        <Headline h1 el="p" className={`${s.title} ${s.noBreak} ${s.margin}`}>
          WHAT CAN WE
          <br className={s.noMobile} />
{' '}
CAN HELP YOU WITH?
</Headline>
        <div className={s.formGroup}>
          {checkboxMap.map(box => (
            <Checkbox
              name={box.value}
              id={box.value}
              key={box.value}
              checked={box.checked}
              className={s.input}
              onChange={handleCheck}
            >
              {box.name}
            </Checkbox>
          ))}
        </div>
        <Headline h1 el="p" className={`${s.title} ${s.margin} ${s.noBottom}`}>
          FINALLY, YOUR PROJECT
          <br className={s.noMobile} />
{' '}
IN YOUR OWN WORDS…
</Headline>
        <p className={s.info}>
          The more detail you can give us the better - including an
          <br className={s.noMobile} />
          idea of timeline and budget:
        </p>
        <Input
          textarea
          className={`${s.input} ${s.textarea}`}
          value={info.state.value}
          {...info.events}
        />
        <Checkbox
          name="join"
          id="join"
          className={`${s.input} ${s.mailList}`}
          onChange={handleEmailList}
          checked={emailList}
        >
          I’d like to join your mailing list
        </Checkbox>
        <div className={s.submitWrapper}>
          <Button
            className={s.submit}
            bordersOn
            type="submit"
            big
            disabled={!submitReady}
          >
            get my quote
          </Button>
        </div>
      </form>
    </Container>
  );
}
