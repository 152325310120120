import { useRef, useEffect, useContext, useState } from 'react';
import { layoutContext } from 'context/layout-provider';

export default function useSetScrollRef() {
  const context = useContext(layoutContext);
  const [mutationCount, setMutationCount] = useState(0);
  const element = useRef();
  const handleScroll = () => {
    if (element && element.current) {
      const { height } = element.current.getBoundingClientRect();
      context.setHeight(height);
      setMutationCount(count => count + 1);
    }
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', handleScroll);
      context.setHeight(null);
    };
  }, []);

  useEffect(() => {
    if (element && element.current) {
      window.onscroll = handleScroll;
    }
  }, [element]);

  useEffect(() => {
    if (mutationCount >= 5) {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [mutationCount]);
  return element;
}
