import React from 'react';
import s from './form-input.module.scss';

export default function FormInput({
  className,
  children,
  required,
  textarea,
  error,
  id,
  round,
  ...props
}) {
  return (
    <div className={`${s.root} ${className || ''} ${error ? s.error : ''}`}>
      {children && (
        <label
          id={props.name}
          htmlFor={id}
          className={required ? s.required : ''}
        >
          {children}
        </label>
      )}
      {textarea ? (
        <textarea id={id} {...props} className={`${round ? s.round : ''}`} />
      ) : (
        <input id={id} {...props} />
      )}
    </div>
  );
}
