import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Icon from 'icon';

import s from './intro-section.module.scss';

export default function IntroSection() {
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <Headline dash h1 el="h3" className={s.headline} smallCenter>
          GREAT, WE LOVE
          {' '}
          <br />
          TO CHAT!
        </Headline>
        <p className={s.text}>
          Either fill out our project form below or send us
          <br />
          an email:
          {' '}
          <a href="mailto:info@cxncollective.com" className={s.infoEmail}>
            info@cxncollective.com
          </a>
        </p>
        <div className={s.personalBlock}>
          <Headline h1 el="p" white className={s.personalTitle}>
            PREFER A MORE PERSONAL TOUCH?
          </Headline>
          <p className={s.personalText}>
            We get it - sometimes you just want to pick up the phone and talk it
            thorough. If that’s you, call us and let’s chat.
          </p>
          <a href="tel:8332255296" className={`${s.personalNumber}`}>
            +1 833-CALL-CXN
            <span>
              2255
              <span>-</span>
              296
            </span>
          </a>
        </div>
      </div>
    </Container>
  );
}
