import React from 'react';
import Meta from 'meta';
import Hero from 'components/page-contact/hero';
import IntroSection from 'components/page-contact/intro-section';
import Form from 'components/page-contact/form';

export default function Contact() {
  return (
    <>
      <Meta
        title="Work With Us | CXN Collective"
        description="Whether you are interested in brand and strategy consultants or wish to grow your business with an UX/UI friendly website, reach out."
        canonical="https://cxncollective.com/contact/"
      />
      <Hero />
      <main>
        <IntroSection />
        <Form />
      </main>
    </>
  );
}
